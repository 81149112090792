export const darkTheme = {
    bg: "rgb(15, 23, 42)", // Updated background
    bgLight: "rgb(15, 16, 35 / var(--tw-bg-opacity))", // Updated light background
    primary: "rgb(15, 23, 42)", // Use the same theme for primary if needed
    text_primary: "#F2F3F4", // Light text for contrast
    text_secondary: "#b1b2b3", // Secondary text color
    card: "rgb(15, 23, 42)", // Updated card background
    card_light: "rgb(15, 16, 35 / var(--tw-bg-opacity))", // Updated light card
    button: "rgb(15, 23, 42)", // Updated button
    white: "#FFFFFF",
    black: "#000000",
};

export const lightTheme = {
    bg: "rgb(15, 23, 42)", // Updated background for light theme
    bgLight: "rgb(15, 16, 35 / var(--tw-bg-opacity))", // Updated light background
    primary: "rgb(15, 23, 42)", // Updated primary color
    text_primary: "#111111", // Dark text for light theme
    text_secondary: "#48494a", // Darker secondary text color
    card: "rgb(15, 23, 42)", // Updated card background
    button: "rgb(15, 23, 42)", // Updated button
};
