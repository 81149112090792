import { Link as LinkR } from 'react-router-dom';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  cursor: pointer;

  @media (min-width: 769px) {
    &:hover > div {
      display: flex;
    }
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: rgb(17, 29, 58);
  padding: 8px 0;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: none; /* Hide by default */

  @media (max-width: 768px) {
    display: flex; /* Show in mobile */
    position: static;
    background: none;
    padding: 0;
    box-shadow: none;
  }

  flex-direction: column;
`;

export const DropdownItem = styled.a`
  padding: 10px 20px;
  color: #e6ebf1;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background: rgb(53, 125, 138);
    color: white;
  }

  @media (max-width: 768px) {
    padding: 8px 0;
  }
`;


export const Nav = styled.nav`
  width: 100%;
  height: 80px;
  background: rgb(15 23 42);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  max-width: 1200px;
  margin: auto;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const NavLogo = styled(LinkR)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const Span = styled.div`
  padding: 0 4px;
  font-weight: bold;
  font-size: 18px;
`;

export const NavItems = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 0 6px;
  list-style: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLink = styled.a`
  color: #c8cfd8;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  :hover {
    color:   #ffffff;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: flex;
    gap: 10px; 
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
    color: #c8cfd8;
  }
`;

export const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  position: absolute;
  top: 80px;
  right: 0;
  width: 100%;
  padding: 12px 40px 24px 40px;
  background: rgb(17, 29, 58);
  transition: all 0.6s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
`;

export const MobileLink = styled.a`
   color: #eef0f3;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  :hover {
    color: #854ce6;
  }
`;

export const LoginButton = styled.button`
  /* Remove the border */
  border: none; 
  background-color: rgb(53, 125, 138); /* Set background color */
  
  justify-content: center;
  display: flex;
  align-items: center;
  height: 36px; 
  border-radius: 18px;
  color: white; /* Set text color to white */
  cursor: pointer;
  padding: 0 15px; 
  font-weight: 500;
  text-decoration: none;
  font-size: 14px; 
  margin-right: 10px; 
  transition: all 0.6s ease-in-out;

  /* Glow effect on hover */
  :hover {
    background: rgb(53, 125, 138); /* Keep the background on hover */
    color: white; /* Keep the text color white on hover */
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6); /* Glow effect */
  }

  @media screen and (min-width: 768px) {
    height: 40px; 
    font-size: 16px; 
    padding: 0 20px; 
  }
`;



export const UserAvatar = styled.div`
  width: 5vw; /* Responsive width based on viewport width */
  height: 5vw; /* Responsive height based on viewport width */
  min-width: 40px; /* Minimum width */
  min-height: 40px; /* Minimum height */
  max-width: 50px; /* Maximum width */
  max-height: 50px; /* Maximum height */
  border-radius: 50%;
  background-color: #ccc; /* Placeholder color */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px; /* Maintain space between button and avatar */
  align-self: center;

  /* Styles for larger screens */
  @media screen and (min-width: 768px) {
    width: 6vw; /* Increased size for larger screens */
    height: 6vw; /* Increased size for larger screens */
  }
`;




