import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Snackbar, Alert } from '@mui/material';
import Cookies from 'js-cookie';
import { useForm, ValidationError } from '@formspree/react';

// Constants
const COOLDOWN_KEY = 'form_submission_cooldown';
const COOLDOWN_DURATION = 30000; // 30 seconds cooldown
const DISCORD_WEBHOOK_URL = process.env.REACT_APP_DISCORD_WEBHOOK_URL;

// Cooldown Countdown Formatter
const formatCountdown = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

// Popup Styles
const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: ${({ theme }) => theme.card || 'white'};
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.text_secondary || 'black'};

  &:hover {
    color: red;
  }
`;

const ContactForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card || 'white'};
  padding: 24px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
  margin-top: 16px;
  gap: 16px;
`;

const ContactInput = styled.input`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.text_secondary || 'gray'};
  outline: none;
  font-size: 16px;
  transition: border 0.3s;

  &:focus {
    border-color: ${({ theme }) => theme.primary || 'blue'};
  }
`;

const ContactInputMessage = styled.textarea`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.text_secondary || 'gray'};
  outline: none;
  font-size: 16px;
  transition: border 0.3s;

  &:focus {
    border-color: ${({ theme }) => theme.primary || 'blue'};
  }
`;

const ContactButton = styled.button`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: linear-gradient(225deg, hsla(271, 100%, 60%, 1) 0%, hsla(294, 100%, 60%, 1) 100%);
  }
`;

const ContactPopup = ({ onClose }) => {
  const formRef = useRef();
  const [open, setOpen] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const FORMSPREE_ENDPOINT = process.env.REACT_APP_FORMSPREE_ENDPOINT;

  const [formspreeState, handleFormspreeSubmit] = useForm(FORMSPREE_ENDPOINT);

  useEffect(() => {
    const cooldown = Cookies.get(COOLDOWN_KEY);
    if (cooldown) {
      const timeLeft = parseInt(cooldown) - Date.now();
      if (timeLeft > 0) {
        setIsCooldown(true);
        setCountdown(Math.floor(timeLeft / 1000));

        const interval = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(interval);
              setIsCooldown(false);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);

        return () => clearInterval(interval);
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (isCooldown || isSubmitting) {
      alert("You must wait before submitting the form again.");
      return;
    }
  
    setIsSubmitting(true);
    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());
  
    // Submit to Formspree first
    try {
      await handleFormspreeSubmit(e);
  
      // Then send to Discord
      const embedMessage = {
        embeds: [
          {
            title: data.subject,
            description: data.message,
            color: 3447003,
            footer: {
              text: `Message from ${data.name} (${data.email})`,
            },
          },
        ],
      };

  
      const discordResponse = await fetch(DISCORD_WEBHOOK_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(embedMessage),
      });
  
      if (!discordResponse.ok) {
        console.error('Error sending to Discord:', discordResponse.statusText);
      }
  
      // Handle cooldown after both submissions succeed
      const cooldownTime = Date.now() + COOLDOWN_DURATION;
      Cookies.set(COOLDOWN_KEY, cooldownTime, { expires: new Date(cooldownTime) });
      setIsCooldown(true);
      setCountdown(COOLDOWN_DURATION / 1000);
  
      setOpen(true);
      setTimeout(onClose, 1000);
  
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <PopupContainer>
      <PopupContent>
        <CloseButton onClick={onClose}>✖</CloseButton>
        <ContactForm ref={formRef} onSubmit={handleSubmit}>
          <ContactInput placeholder="Your Email" name="email" type="email" required disabled={isCooldown || isSubmitting} />
          <ValidationError prefix="Email" field="email" errors={formspreeState.errors} />
          <ContactInput placeholder="Your Name" name="name" required disabled={isCooldown || isSubmitting} />
          <ContactInput placeholder="Subject" name="subject" required disabled={isCooldown || isSubmitting} />
          <ContactInputMessage placeholder="Message" rows="4" name="message" required disabled={isCooldown || isSubmitting} />
          <ValidationError prefix="Message" field="message" errors={formspreeState.errors} />
          <ContactButton type="submit" disabled={isCooldown || isSubmitting}>
            {isSubmitting ? 'Submitting...' : isCooldown ? `Wait ${formatCountdown(countdown)}` : 'Send Message'}
          </ContactButton>
        </ContactForm>
        <Snackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)}>
          <Alert severity="success">Message sent successfully!</Alert>
        </Snackbar>
      </PopupContent>
    </PopupContainer>
  );
};

export default ContactPopup;
