import React, { useState } from 'react';
import {
  Nav,
  NavLink,
  NavbarContainer,
  NavItems,
  ButtonContainer,
  MobileIcon,
  MobileMenu,
  MobileLink,
  DropdownContainer,
  DropdownMenu,
  DropdownItem,
  LoginButton,
  UserAvatar,
} from './NavbarStyledComponent';
import { FaBars, FaChevronDown } from 'react-icons/fa';
import { SignedIn, SignedOut, UserButton, SignIn } from '@clerk/clerk-react';
import { Close } from '@mui/icons-material';

const Navbar = ({ setOpenModal, setShowContactPopup }) => {
  const [isOpen, setIsOpen] = useState(false); // State for mobile menu
  const [showDropdown, setShowDropdown] = useState(false); // State for socials dropdown
  const [showSignIn, setShowSignIn] = useState(false); // State for sign-in modal

  // Handle dropdown toggle
  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowSignIn(false);
    }
  };

  const handleContactClick = () => {
    setShowContactPopup(true);
    console.log("Contact Popup Opened");
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <MobileIcon onClick={() => setIsOpen(!isOpen)}>
            <FaBars />
          </MobileIcon>
          <NavItems>
            <NavLink href="#about">About</NavLink>
            <NavLink href='#skills'>Skills</NavLink>
            <NavLink href='#experience'>Experience</NavLink>
            <NavLink href='#projects'>Projects</NavLink>
            <NavLink href='#education'>Education</NavLink>

            {/* Socials Dropdown (for desktop) */}
            <DropdownContainer
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >
              <NavLink as="div">
                Socials <FaChevronDown />
              </NavLink>
              {showDropdown && (
                <DropdownMenu>
                  <DropdownItem href="https://aayushniraula07.com.np/"  target="_blank" >Blogs</DropdownItem>
                  <DropdownItem href="https://aayushniraula.featurebase.app/" target="_blank" >Forum</DropdownItem>
                </DropdownMenu>
              )}
            </DropdownContainer>
          </NavItems>
          <ButtonContainer>
            <SignedOut>
              <LoginButton variant="outline" onClick={() => setShowSignIn(true)}>
                Login
              </LoginButton>
            </SignedOut>
            <SignedIn>
              <LoginButton variant="outline" onClick={handleContactClick}>
                Contact
              </LoginButton>
              <UserButton>
                <UserAvatar />
              </UserButton>
            </SignedIn>
          </ButtonContainer>
          {isOpen && (
            <MobileMenu isOpen={isOpen}>
              <Close onClick={() => setIsOpen(false)} style={{ cursor: 'pointer', alignSelf: 'flex-end' }} />
              <MobileLink href="#about" onClick={() => setIsOpen(false)}>About</MobileLink>
              <MobileLink href='#skills' onClick={() => setIsOpen(false)}>Skills</MobileLink>
              <MobileLink href='#experience' onClick={() => setIsOpen(false)}>Experience</MobileLink>
              <MobileLink href='#projects' onClick={() => setIsOpen(false)}>Projects</MobileLink>
              <MobileLink href='#education' onClick={() => setIsOpen(false)}>Education</MobileLink>

               {/* Socials as regular nav items for mobile */}
              <MobileLink href="https://aayushniraula07.com.np/"  target="_blank" onClick={() => setIsOpen(false)}>Blogs</MobileLink>
              <MobileLink href="https://aayushniraula.featurebase.app/" target="_blank" onClick={() => setIsOpen(false)}>Forum</MobileLink>
              <SignedOut>
                <MobileLink onClick={() => { setShowSignIn(true); setIsOpen(false); }}>Login</MobileLink>
              </SignedOut>
              <SignedIn>
                <MobileLink onClick={handleContactClick}>Contact Me</MobileLink>
              </SignedIn>
            </MobileMenu>
          )}
        </NavbarContainer>
      </Nav>

      {showSignIn && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={handleOverlayClick}>
          <SignIn signUpForceRedirectUrl="/onboarding" fallbackRedirectUrl="/onboarding" />
          <Close onClick={() => setShowSignIn(false)} style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }} />
        </div>
      )}
    </>
  );
};

export default Navbar;