export const Bio = {
  name: "Aayush Niraula",
  roles: [
    "FiveM Developer",
    "Web Developer",
    "UI/UX Designer",
    "Programmer",
  ],
  description:
    "Hello! I'm Aayush Niraula, a passionate web developer and FiveM expert. With a focus on crafting immersive gaming experiences, I specialize in developing custom scripts and fully functional servers. My journey in web development began in 2019, and I have honed my skills in both front-end and back-end technologies, ensuring high-quality, engaging applications. Explore my portfolio to see how I bring ideas to life through code.",
  github: "https://github.com/aayushniraula10",
  discord: "https://discord.gg/zRCEY5Fp2H",
  linkedin: "https://www.linkedin.com/in/aayushniraula/",
  twitter: "https://l.facebook.com/l.php?u=https%3A%2F%2Ftwitter.com%2Faaushniraulaa%3Ffbclid%3DIwZXh0bgNhZW0CMTAAAR1GyHUAaR-w5bMk1sQ9ykWR4wsX3T73ScFUYIi6RfQ8hWa_9HrHsmO_KwA_aem_2p--s4OYFoGuwYQf_c1wDw&h=AT2_8Cqfg2dHGFiqAlMeVRczX3HxI_x32ryh6QUTEnb_g61Ec9kz8obMmMYDqymBC2dKmo4HHKrOCN2umGT_5A2T0BA_FZvB9STXXvAYX7hDeU1rpDOYdsfQaRo2ddGQI2g7Fe6BYYxOKWX4yjmH",
  insta: "https://www.instagram.com/aayush_niraulaa/",
  facebook: "https://www.facebook.com/aayushniraula01.com.np",
};


export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Vite Js",
        image: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Vite%20logo.png?alt=media&token=1efe6f04-a6a2-4c60-aca8-4580691df4e1",
      },
      {
        name: "Bootstrap",
        image: "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
      {
        name: "LUA",
        image: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/lua%20logo.png?alt=media&token=b03dce4f-5e7e-4a4a-8380-389882c9814f",
      },
      {
        name: "MySQL",
        image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "Supabase",
        image: "https://supabase.com/docs/_next/image?url=%2Fdocs%2Fsupabase-light.svg&w=96&q=75&dpl=dpl_86ui24aA1j1wyQjkeC2HHkC5MbXE",
      },
      {
        name: "Clerk",
        image: "https://cdn.sanity.io/images/o0o2tn5x/production/2399b991025c365aafaa6fca85d91deac801e654-1046x1046.png",
      },
    ],
  },
  {
    title: "Software Development",
    skills: [
      {
        name: "C",
        image: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/C%20program.jpeg?alt=media&token=d3367d50-666e-4a7c-91e2-a82b5986bba4",
      },
      {
        name: "C++",
        image: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/C%2B%2B%20image.png?alt=media&token=7d2de750-8748-444a-8716-27b8cd2b0d5a",
      },
      {
        name: "XML",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBMw6_RdwKQ9bDFfnKDX1iwMl4bVJEvd9PP53XuIw&s",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Cfx.re",
        image: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/fivem%20logo.png?alt=media&token=edbec692-dfd9-4bdb-b432-6744de0ea281",
      },
      {
        name: "Git",
        image: "https://git-scm.com/images/logo@2x.png",
      },
      {
        name: "GitHub",
        image: "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Hosting / VPS",
        image: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/vpsimage.jpeg?alt=media&token=54af5f5b-c62f-4052-9177-07a7172645d3",
      },
      {
        name: "Netlify",
        image: "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "Vercel",
        image: "https://vercel.com/vercel-docs/_next/static/media/vercel-logotype-light.700a8d26.svg",
      },
      {
        name: "VS Code",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Adobe Photoshop",
        image: "https://helpx.adobe.com/content/dam/help/mnemonics/ps_cc_app_RGB.svg",
      },
      {
        name: "Figma",
        image: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/figmalogo.jpg?alt=media&token=b8a1b43a-541a-490c-a8f8-b9f5cee32096",
      },
    ],
  },
];


export const experiences = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Libert%C3%A9%20Roleplay.png?alt=media&token=72aed2a1-0e75-4412-bd3e-08c0d415f547",
    role: "Full Stack Lead Developer",
    company: "Liberté Roleplay France Pvt. Ltd.",
    date: "June 2020 - Dec 2022",
    desc: "As a Full Stack Lead Developer, I specialize in FiveM development, where I create custom scripts, troubleshoot bugs, and maintain server performance. My role involves optimizing gameplay experiences and ensuring seamless functionality across platforms. I possess strong communication skills, enabling effective collaboration with team members and stakeholders, while leading development projects from inception to deployment.",
    skills: [
      "LUA",
      "JavaScript",
      "HTML",
      "CSS",
      "Windows VPS",
      "Custom Scripting",
    ],
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Fjordland%20Roleplay.png?alt=media&token=6cf666d5-0e1f-4d03-b040-b4ab4a71a9ea", // Replace with actual image URL
    role: "Full Stack Developer",
    company: "Fjordland Roleplay Norway",
    date: "Jan 2021 - Sept 2021",
    desc: "As a Full Stack Developer at Fjordland Roleplay, I focused on enhancing user experiences through custom scripting and effective bug fixing while collaborating closely with team members.",
    skills: [
      "LUA",
      "JavaScript",
      "HTML",
      "CSS",
      "Custom Scripting",
    ],
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Nouvelle%20France%20Roleplay.png?alt=media&token=0de3d7b1-6682-45bd-a0da-434d227593bd", // Replace with actual image URL
    role: "Developer & Asset Developer",
    company: "Nouvelle France Roleplay France",
    date: "Feb 2020 - Aug 2022",
    desc: "In my role at Nouvelle France Roleplay, I developed custom scripts and ensured optimal server performance while maintaining effective communication with the team.",
    skills: [
      "LUA",
      "JavaScript",
      "HTML",
      "CSS",
      "Windows VPS",
      "Custom Scripting",
    ],
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Great%20Southern.png?alt=media&token=73cfeb88-805c-4d2d-a709-a747ac1ee214", // Replace with actual image URL
    role: "Lead Developer & Support Team",
    company: "Great Southern Roleplay",
    date: "Jan 2023 - Dec 2023",
    desc: "As a Lead Developer at Great Southern Roleplay, I led development projects, optimized server performance, and implemented custom scripts to enhance the gameplay experience.",
    skills: [
      "LUA",
      "JavaScript",
      "HTML",
      "CSS",
      "Windows VPS",
      "Custom Scripting",
    ],
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/lsl_logo_fivem.png?alt=media&token=69de5165-3dc5-4683-9bc1-5d95e1cda5e1", // Replace with actual image URL
    role: "Main Developer & Admin",
    company: "Los Santos Legends Roleplay",
    date: "March 2023 - Oct 2024",
    desc: "In my role as Lead Developer, I focused on server optimization, custom scripting, and team collaboration to ensure a seamless gameplay experience.",
    skills: [
      "LUA",
      "JavaScript",
      "HTML",
      "CSS",
      "Windows VPS",
      "Custom Scripting",
    ],
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/everest_rp%201.0.png?alt=media&token=dfb8af11-3d65-4eca-ba2f-f207c51e8a1f", // Replace with actual image URL
    role: "Owner & Developer",
    company: "Everest City Roleplay",
    date: "Dec 2024 - Jan 2023",
    desc: "As the Owner of Everest City Roleplay, I oversaw all development activities, managed server operations, and led a team to create an immersive roleplay environment.",
    skills: [
      "LUA",
      "JavaScript",
      "HTML",
      "CSS",
      "Windows VPS",
      "Custom Scripting",
    ],
  },
];

export const education = [
  {
    id: 0,
    img: "https://pcas.edu.np/images/logo2.png",
    school: "Purwanchal University | Pathibhara Engineering College, Jhapa, Nepal",
    date: "Sept 2023 - Nov 2024",
    grade: "XXXX",
    desc: "I am currently pursuing a Bachelor's degree in Engineering in Computer Science at Pathibhara Engineering College, enrolled in my second semester under Purwanchal University. My coursework includes Digital Logics, Object-Oriented Programming, Operating Systems, and Computer Networks. In addition to my academic studies, I have engaged in various self-motivated projects that enhance my learning and practical skills in software development.",
    degree: "Bachelor of Engineering (B.E.) in Computer Engineering",
  },
  {
    id: 1,
    img: "https://scontent.fbdp2-1.fna.fbcdn.net/v/t39.30808-6/358121788_789995379793143_5329317172575316456_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=sGX-DLHi12YQ7kNvgEFxxDs&_nc_ht=scontent.fbdp2-1.fna&_nc_gid=APhiyzR2eIEJiT7PWD36rnr&oh=00_AYAILDCqWbDxyluJOs3GMB6kIt6JhbPWI9sDUPzSLICs1A&oe=6705F34C",
    school: "Mahendra Ratna College, Jhapa, Nepal NEB",
    date: "Apr 2021 - March 2023",
    grade: "3.26 GPA",
    desc: "Completed the ISC (XII) with a focus on Science and Computer Science. Actively participated in seminars and workshops on Artificial Intelligence and Robotics, enhancing my understanding of emerging technologies. Developed strong presentation skills through various academic projects, where I collaborated with peers to deliver informative and engaging presentations.",
    degree: "XII, Science with Computer Science",
  },
  {
    id: 2,
    img: "https://scontent.fbdp2-1.fna.fbcdn.net/v/t39.30808-6/447732282_880785547423283_6229857554790004479_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=x_6_7BnAzi8Q7kNvgH0WfFX&_nc_ht=scontent.fbdp2-1.fna&_nc_gid=ADcu29T_tmexfvqUuspXo4G&oh=00_AYAKsKAT5ZsCi53GFX0LXFfRn8Xh4Sw-bbHGhkq4DcIpzw&oe=6705FB8B",
    school: "Balmiki Education Foundation NEB",
    date: "Jan 2020 - Feb 2021",
    grade: "3.94 GPA",
    desc: "Studied Class X with Optional Math and Optional Science, achieving a high GPA. Actively engaged in various science exhibitions, showcasing projects that emphasized practical applications of scientific concepts. As a member of the Robotics Club, I contributed to team projects that explored innovative solutions, enhancing my teamwork and problem-solving skills.",
    degree: "X, Science with Mathematics",
  },
];


export const projects = [
  {
    id: 9,
    title: "ECRP Webpage",
    date: "Oct 2023 - Nov 2024",
    description:
      "The ECRP website is a multi-purpose platform created for a FiveM server, featuring essential pages such as Home, About, Get Whitelisted, Streamers, Team, Rules, and EDM. This user-friendly site enhances the gaming experience by providing clear information and easy navigation for players. It effectively streamlines the whitelist application process while fostering community engagement through dedicated sections for streamers and team members.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/ecrp_webpageimage.png?alt=media&token=0f6c7c83-5df0-43b6-b647-fe00e485f001",
    tags: [
      "WebHosting",
      "HTML",
      "CSS",
      "Javascript",
      "React Js",
      "Node Js",
    ],
    category: "web app",
    github: "",
    webapp: "https://www.ecrp.live/",
    member: [
      {
        name: "Aayush Vaish",
        img: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/spadyimage.png?alt=media&token=a619876f-5f2a-4667-8881-43e0cf3f9ecb",
        linkedin: "",
        github: "https://github.com/SpadyisLive",
      },
    ],
  },
  {
    id: 0,
    title: "Personal Portfolio V1",
    date: "Feb 2019 - Feb 2019",
    description:
      "This personal portfolio showcases my work and skills as a web developer and YouTuber. The site features sections for my portfolio, services, skills, and a contact form. Users can explore my projects, which include responsive web design, FiveM development, and app design. The portfolio reflects my journey in technology, highlighting my creative abilities and ongoing learning in web and software development.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/personal_portfolio_a.png?alt=media&token=7535a21e-5ee4-4b01-963a-86644b2beaa2",
    tags: ["Javascript", "HTML", "CSS"],
    category: "web app",
    github: "",
    webapp: "https://aayushniraula01.com.np/",
  },
  {
    id: 15,
    title: "Personal Portfolio V2",
    date: "Oct 2024 - Oct 2024",
    description:
      "This personal portfolio showcases my work and skills as a web developer and YouTuber. The site features sections for my portfolio, services, skills, and a contact form. Users can explore my projects, which include responsive web design, FiveM development, and app design. The portfolio reflects my journey in technology, highlighting my creative abilities and ongoing learning in web and software development.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/portfolio_v2_updated.jpg?alt=media&token=3af5d1b9-3bca-4f90-b403-3b2b1d0d3a37",
    tags: ["Javascript", "HTML", "CSS", "React Js","Vue Js", "Bootstrap", "Node JS", "Clerk", "Supabase", "GetForm", "Vercel", "AI ChatBot", "LiveChat" ],
    category: "web app",
    github: "",
    webapp: "https://aayushniraula01.com.np/",
  },
{
  "id": 2,
  "title": "Stock Management System",
  "date": "July 2024 - Aug 2024",
 "description": "A robust console-based stock management system developed in C++. This application facilitates user-to-user share trading, allowing individuals to buy and sell shares seamlessly. Users can create and manage profiles, while administrators benefit from advanced admin-level features to oversee transactions and user activity. The super admin has comprehensive control over the entire application, enabling the management of companies and the creation of stocks. Additionally, shares can be listed for sale, providing a streamlined marketplace for users. The system is secured with CAPTCHA to ensure a safe trading environment.",
  "image": "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/stockmanagement.png?alt=media&token=b581e08e-efa3-4650-b091-6e9b7e1b11b5",
  "tags": ["C++", "Stock Market", "Financial Analysis", "Trading System", "Object-Oriented Programming", "Console Application"],
  "category": "Object Oriented Programming",
  "github": "",
  "webapp": ""
},

  {
    id: 3,
    title: "Los Santos Legends Roleplay",
    date: "March 2023 - Oct 2024",
    description:
      "Los Santos Legends is the FiveM Roleplay home you have been searching for. The other owners of LSL and I are serious roleplayers that are obssessed with the unique experience that gta roleplay can be. However, we realized through our many years of playing fivem roleplay, that most servers are poorly managed. Staff is rude and misguided, the police force and medical teams are unprofessional and or not active, the economy is terrible due to negligence and pay to win donation packages, and there is essentially no meaningful character progression outside of earning more and more money. These pervasive issues plague the majority of so called ( free-to-play ) fivem servers that exist today, and are the exact problems that we strive to perfect at LSL. Our mission is to create the most engaging, hyper-realistic, immersive roleplay experience for any player that really wants to LIVE the experience of a criminal or law abiding citizen alike. ",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/lsl_logo_fivem.png?alt=media&token=69de5165-3dc5-4683-9bc1-5d95e1cda5e1",
    tags: ["LUA", "JavaScript", "HTML", "CSS", "Windows VPS", "TxAdmin"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
  },
  {
    id: 10,
    title: "Great Southern Roleplay",
    date: "Jan 2023 - Dec 2023",
    description:
      "Great Southern Roleplay is an immersive, Australian-based FiveM server that offers a unique and authentic roleplaying experience. Set against the backdrop of Australia’s diverse landscapes, from bustling cities to the rugged Outback, the server provides players with a wide range of roleplay opportunities. Whether you want to enforce the law as part of the local police force, manage a business, explore the wild terrains, or dive into civilian life, Great Southern Roleplay delivers a dynamic and engaging environment. With a strong focus on community, realism, and interactive storytelling, it’s the perfect place for players looking for an exciting and authentic Australian roleplaying experience.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Great%20Southern.png?alt=media&token=73cfeb88-805c-4d2d-a709-a747ac1ee214",
      tags: ["LUA", "JavaScript", "HTML", "CSS", "LinuxVPS", "TxAdmin", "AntiCheat"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
  },
  {
    id: 1.5,
    title: "Liberté Roleplay France",
    date: "June 2020 - Dec 2022",
    description:
      "Liberté Roleplay is my first development project as a lead developer, where I transformed the server from its foundational stages to a thriving community. This French-themed roleplay server for FiveM immerses players in the world of Grand Theft Auto V, featuring engaging roleplay scenarios, dynamic gang systems, and drug trade mechanics. With a focus on realism and community engagement, Liberté Roleplay offers a vibrant environment where players can explore their characters' stories and interact in a richly detailed virtual world. Through dedicated development and a commitment to quality, I successfully raised this server to its peak, making it a premier destination for French roleplay enthusiasts.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Libert%C3%A9%20Roleplay.png?alt=media&token=72aed2a1-0e75-4412-bd3e-08c0d415f547",
      tags: ["LUA", "JavaScript", "HTML", "CSS", "Game Server", "TxAdmin", "ESX Core", "MyFirst Server"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
  },
  {
    id: 500,
    title: "Fjordland Roleplay Norway",
    date: "Jan 2021 - Sept 2021",
    description:
      "Fjordland Roleplay is an engaging roleplay server that immerses you in the beautiful Norwegian landscape. Players have the opportunity to explore fjords, mountains, and picturesque villages while creating their own stories. With a focus on authentic interactions and a strong community, Fjordland offers a unique experience where you can live out your Norwegian dream life. From traditional professions to exciting adventures, the possibilities are endless!",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Fjordland%20Roleplay.png?alt=media&token=6cf666d5-0e1f-4d03-b040-b4ab4a71a9ea",
      tags: ["LUA", "JavaScript", "HTML", "CSS", "Game Server", "TxAdmin", "ESX Core"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
  },
  {
    id: 7,
    title: "Everest City Roleplay 1.0",
    date: "OCT 2023 - Jan 2021",
    description:
      "Everest City Roleplay 1.0 is a highly customized FiveM server built with seamless functionality and no bugs. The project features a custom, optimized inventory system, tailored scripts, and assets that enhance the overall gameplay. With a focus on performance and player experience, the server runs on a Windows VPS, utilizing TxAdmin and AntiCheat for secure and smooth operations. This Nepali-based server offers a unique, lag-free roleplaying environment for players, ensuring engaging gameplay across all levels.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/everest_rp%201.0.png?alt=media&token=dfb8af11-3d65-4eca-ba2f-f207c51e8a1f",
      tags: ["LUA", "JavaScript", "HTML", "CSS", "WindowsVps", "TxAdmin", "AntiCheat"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
    member: [
      {
        name: "Aayush Vaish",
        img: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/spadyimage.png?alt=media&token=a619876f-5f2a-4667-8881-43e0cf3f9ecb",
        linkedin: "",
        github: "https://github.com/SpadyisLive",
      },
    ],
  },
  {
    id: 112,
    title: "Everest City Roleplay 2.0",
    date: "May 2024 - DEC 2024",
    description:
      "Building on the foundation of the original server, Everest City Roleplay 2.0 introduces significant upgrades and enhancements. This version includes new custom features, such as the introduction of gangs, weapons, and exclusive assets, all optimized for high-performance gameplay. With a robust 120-player base and a huge influx of traffic, the server handles heavy loads seamlessly. The addition of custom vehicles, smoother scripts, and an upgraded anti-cheat system ensures a richer roleplay experience. As one of the most active Nepali-based servers, Everest City Roleplay 2.0 stands out with its highly optimized performance and cutting-edge custom work.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/ecrp_2.0_logo.png?alt=media&token=98dda629-8381-422b-851e-6eddb211307f",
      tags: ["LUA", "JavaScript", "HTML", "CSS", "WindowsVps", "TxAdmin", "AntiCheat"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
    member: [
      {
        name: "Aayush Vaish",
        img: "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/spadyimage.png?alt=media&token=a619876f-5f2a-4667-8881-43e0cf3f9ecb",
        linkedin: "",
        github: "https://github.com/SpadyisLive",
      },
    ],
  },
  {
    id: 100,
    title: "Dog Town RP",
    date: "July 2024 - SEP 2024",
    description:
      "Dog Town RP is a dynamic and immersive FiveM server that offers players a gritty roleplaying experience centered around gangs, jobs, and city life. Players can dive into various roles, from joining powerful gangs and controlling territories to taking up legal jobs like law enforcement, EMTs, mechanics, and more. With a fully customized economy, realistic job systems, and an engaging criminal underworld, Dog Town RP provides a balanced mix of high-stakes action and everyday life. Whether you’re rising through the ranks of a gang or building a legitimate career, Dog Town RP delivers an exciting and ever-evolving roleplay environment.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/dogtown%20rp.png?alt=media&token=d9905dd3-9a29-447a-96ee-197362aa8a09",
      tags: ["LUA", "JavaScript", "HTML", "CSS", "WindowsVps", "TxAdmin"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
  },
  {
    id: 100,
    title: "Napiers Asylum",
    date: "May 2024 - Aug 2024",
    description:
      "Napiers Asylum is an adrenaline-fueled racing server built for FiveM. Designed for high-speed action, it offers a fully customized racing experience with a variety of tracks, vehicles, and competitive features. Players can participate in organized races, street challenges, and time trials, all within a highly optimized environment. With custom cars, seamless performance, and dynamic race events, Napiers Asylum provides an immersive racing experience for speed enthusiasts on the FiveM platform.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Napiers%20Asylum.png?alt=media&token=0c8984f0-8a84-4b45-8b98-8d8d0f229251",
      tags: ["LUA", "JavaScript", "HTML", "CSS", "WindowsVps", "TxAdmin", "Standalone Server", "Racing"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
  },
  {
    id: 555,
    title: "Meta Roleplay 3.0",
    date: "Sept 2024 - Oct 2024",
    description:
      "Meta Roleplay 3.0 is the latest evolution of a cutting-edge FiveM RP framework, built on the success of versions 1.0 and 2.0. Starting with core jobs and roleplay features in 1.0, and expanding with custom gangs and enhanced interactions in 2.0, Meta Roleplay 3.0 introduces advanced jobs, dynamic gangs, player-owned housing, and an optimized economy. Each version has improved performance and expanded features, making 3.0 the most immersive and seamless roleplaying experience yet.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/metaroleplay3.0.png?alt=media&token=a16ac435-6e66-4226-bc4b-4c33e5efa2cb",
      tags: ["LUA", "JavaScript", "HTML", "CSS", "WindowsVps", "TxAdmin"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
  },
  {
    id: 8,
    title: "Nouvelle France Roleplay France",
    date: "FEB 2020 - AUG 2022",
    description:
      "Nouvelle France Roleplay is a premier French-themed FiveM server that offers one of the best immersive roleplaying experiences. Set in the heart of a beautifully crafted French environment, the server features a dynamic array of jobs, gangs, and intricate storylines. Whether you're navigating the bustling streets of a Paris-inspired city or the serene countryside, Nouvelle France RP provides endless roleplay possibilities with highly optimized performance and custom features. Known for its vibrant community and deep commitment to realism, this server stands as one of the best in French roleplay.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/assets-c2031.appspot.com/o/Nouvelle%20France%20Roleplay.png?alt=media&token=0de3d7b1-6682-45bd-a0da-434d227593bd",
      tags: ["LUA", "JavaScript", "HTML", "CSS", "WindowsVps", "TxAdmin", "Custom Core", "Anticheat", "SecureEvents"],
    category: "FiveM Projects",
    github: "",
    webapp: "https://servers.fivem.net/",
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
