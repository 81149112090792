export default {
    // Temp fonts
    fonts: {
      title: "Space Grotesk, sans-serif",
      main: "Space Grotesk, sans-serif"
    },
    // Colors for layout
// New theme with adjusted colors
colors: {
  primary1: "rgb(15, 23, 42)", // Darker primary color
  background1: "rgba(15, 16, 35, var(--tw-bg-opacity))", // Opacity variable for backgrounds
  button: "rgba(53, 125, 138, var(--tw-bg-opacity))", // Button background color with opacity
  background2: "rgba(15, 16, 35, var(--tw-bg-opacity))", // Same background color but with opacity
  text: "#C8CFD8", // Light text for contrast
  text1: "#F2F5F7", // White for key text
  text2: "#626970", // Muted gray for secondary text
  text3: "#575C66", // Darker gray for tertiary text
  footerBackground: "rgb(15, 23, 42)", // Footer background with dark primary color
},

    
    // Breakpoints for responsive design
    breakpoints: {
      sm: 'screen and (max-width: 640px)',
      md: 'screen and (max-width: 768px)',
      lg: 'screen and (max-width: 1024px)',
      xl: 'screen and (max-width: 1280px)'
    },
  }