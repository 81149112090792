import { ThemeProvider } from "styled-components";
import { useState } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js';
import Navbar from "./components/Navbar/index.js";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import HeroSection from "./components/HeroSection/index.js";
// import About from "./components/About/index.js";
import Skills from "./components/Skills/index.js";
import Projects from "./components/Projects/index.js";
import ContactPopup from "./components/Contact/ContactPopup.js"; // Updated import
import Footer from "./components/Footer/index.js";
import Experience from "./components/Experience/index.js";
import Education from "./components/Education/index.js";
import ProjectDetails from "./components/ProjectDetails/index.jsx";
import styled from "styled-components";
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  const [showContactPopup, setShowContactPopup] = useState(false); // State for the contact popup

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <AuthProvider> {/* Wrap with AuthProvider */}
        <Router>
          <Navbar setOpenModal={setOpenModal} setShowContactPopup={setShowContactPopup} /> {/* Pass the function here */}
          <Body>
            <HeroSection />
            <Wrapper>
              <Skills />
              <Experience />
            </Wrapper>
            <Projects openModal={openModal} setOpenModal={setOpenModal} />
            <Wrapper>
              <Education />
            </Wrapper>
            <Footer />
            {openModal.state && (
              <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
            )}
        {showContactPopup && <ContactPopup onClose={() => setShowContactPopup(false)} />}

            {/* Clerk Authentication Buttons */}
            <header>
              <SignedOut>
                <SignInButton />
              </SignedOut>
              <SignedIn>
                <UserButton />
              </SignedIn>
            </header>
          </Body>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

