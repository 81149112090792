import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ClerkProvider } from '@clerk/clerk-react';
import { ThemeProvider } from 'styled-components';
import theme from './themes/default'; // Adjust the path as necessary

const clerkFrontendApi = process.env.REACT_APP_CLERK_FRONTEND_API;

ReactDOM.render(
  <ClerkProvider publishableKey={clerkFrontendApi}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ClerkProvider>,
  document.getElementById('root')
);
